<script setup>
import dayjs from "dayjs";
import repository from '@/api/repository';
import store from '@/store'

const onChangeLocale = (event) => {
  const locale = event.target.value;
  localStorage.setItem('locale',locale)
  dayjs.locale(locale);
  repository.auth.setLocale({locale:locale}).then(() => {
    store.dispatch('refreshUser');
  });

}
</script>

<template>
  <div class="locale-changer w-100">
    <select v-model="$i18n.locale" @change="onChangeLocale" class="form-select form-select-transparent form-select-sm">
      <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ $t(`locale.${locale}`) }}</option>
    </select>
  </div>
</template>

<style scoped lang="scss">

</style>
