import api from '../api';

const BASE_PATH = '/api/subscription';
const PAYMENT_PATH = '/api/payment';
export default {
  getSubscription() {
    return api().get(`${BASE_PATH}/`);
  },
  cancelSubscription(params) {
    return api().post(`${BASE_PATH}/cancel`,  params );
  },

  getPaymentMethod() {
    return api().get(`${BASE_PATH}/payment-methods/default`);
  },
  removePaymentMethod(params) {
    return api().post(`${BASE_PATH}/payment-methods/remove`,  params );
  },

  getInvoices() {
    return api().get(`${BASE_PATH}/invoices`);
  },

  getPackage() {
    return api().get(`${BASE_PATH}/package`);
  },

  initiatePayment(params) {
    return api().post(`${PAYMENT_PATH}/initiate`,  params );
  },
  checkCoupon(params) {
    return api().get(`${PAYMENT_PATH}/coupon`,{params});
  },
};
